 
import React, { useState, useEffect } from 'react';
import './SearchMap.css';
import SearchForm from 'components/SearchForm/SearchForm';
import SearchResult from 'components/SearchResult/SearchResult';
import SearchControlPanel from 'components/SearchResult/SearchControlPanel/SearchControlPanel';
import SearchResultMap from 'components/SearchResultMap/SearchResultMap';
import { useNavigate } from "react-router-dom";
import useWindowSize from "utils/useWindowSize";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import SkeletonSearch from "../SkeletonSearch"
  
  

 const SearchMap = (props) => {
  const { width } = useWindowSize();
  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [mapData, setMapData] = useState([]);
  const [mapIsShwon, setMapVisability] = useState(true);
  const [displayMode, setDisplayMode] = useState("map");
  const [params, setParams] = useState({});
  const [enableImageScroll, setEnableImageScroll] = useState(''); 
   

  const navigate = useNavigate();

  function upMapState(val) {
    setMapVisability(!val);
  }

  function changeLocation(e) {
    setParams({ ...params, Location: e.target.value });
  }

   function changeSorting(Sort, SortDirection) {
     setParams({ ...params, SortDirection, Sort });
   }
     
   useEffect(() => {
      
      if (mapIsShwon === false) {
        setDisplayMode('fullWidth');
      }
      if (mapIsShwon === true) {
        setDisplayMode('map');
      }
    }, [mapIsShwon, displayMode]);

    useEffect(() => {
      if (width< window.lg){
        setMapVisability(false);
      }else{
        setMapVisability(true);
      }
    }, [width]);



  function transferDataToMap(val){
    setMapData(val);
  }

     
  const transferAllDataParams =  (val) => {
    let urlParams = '?' + new URLSearchParams(val).toString();
    navigate("/search/" + urlParams);
    setParams(val);
  }
    
  const transfercrollImageState = (val) => {
    
    setEnableImageScroll(val);
  }

  const setLoadingStatus = (val) => {
    setIsLoading(val);
  }

   const handleError = (err) => {
     
     if(err){
       setError(true);
       setErrorMessage(err);
     }else{
       setError(false);
       setErrorMessage('');
     }
   }    

   if (error){
     return <h1>Something went wrong. HTTP error: {errorMessage}</h1>;
   }

  return (
    <div className={" tw-px-3 search-reult-page-content"}>
      <SearchForm transferAllData={transferAllDataParams} liftUpScrollImageState={transfercrollImageState} pageID={props.pageId} keyID={props.keyID} changeLocation={changeLocation} view="horizontal" Currency={props.Currency} isLoading={isLoading} loadError={handleError}  />
      <div className="tw-grid tw-grid-cols-5 tw-gap-3">
          
        {mapIsShwon ? <div className="tw-col-span-3 lg:tw-col-span-3">
          <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />
          <SearchResult transferDataToMap={transferDataToMap} enableImageScrollParam={enableImageScroll} displayMode={displayMode} params={params} setParams={setParams} setLoadingStatus={setLoadingStatus}  />
            </div> : 
          <div className="tw-col-span-5 order-lg-2">
                <SearchControlPanel upMapState={upMapState} updateSorting={changeSorting} />
                <SearchResult transferDataToMap={transferDataToMap} enableImageScrollParam={enableImageScroll} displayMode={displayMode} params={params} setParams={setParams} setLoadingStatus={setLoadingStatus} />
              </div>  
          }
          {
          (mapIsShwon && width > window.lg) ? <div className="lg:tw-columns-1 tw-col-span-2">
              <div className="tw-sticky tw-top-0">
                <div className="">
                <Wrapper apiKey={props.GoogleMapAPIKey}  >
                    <SearchResultMap mapData={mapData} />
                  </Wrapper>
                </div>
              </div>
            </div> : null
          }
          {
            (mapIsShwon && width < window.lg) ? 
            <div className="tw-fixed tw-w-full tw-h-full tw-z-[9] tw-top-0 tw-left-0">
              <div className="map">
                <div className="">
                  <Wrapper apiKey={props.GoogleMapAPIKey}  >
                    <SearchResultMap mapData={mapData} />
                  </Wrapper>
                </div>
              </div>
            </div> : null
          }
          
      </div>
    </div>
  )
};

SearchMap.propTypes = {};

SearchMap.defaultProps = {};

export default SearchMap;
