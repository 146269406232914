// CarouselWidget.jsx
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';// Import Swiper styles for navigation (arrows)
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import './CarouselWidget.scss'; // Add a CSS module to style your component
import useWindowSize from "utils/useWindowSize";

const CarouselWidget = ({ data }) => {
    const { Title, SubTitle, Content, FeaturedItems } = data;
    const [slidesPerView, setSlidesPerView] = useState(5);
    const { width } = useWindowSize();

    useEffect(() => {
        if (width < 768) {
            setSlidesPerView(1);
        } else if (width < 1024) {
            setSlidesPerView(3);
        } else {
            setSlidesPerView(5);
        }
    }, [width]);

    const [gridClasses, setGridClasses] = useState('sm:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-gap-y-6');

    useEffect(() => {
        if (FeaturedItems){
            const itemsCount = FeaturedItems.length;
            let itemsInRow;
            if (itemsCount > 4) {
                itemsInRow = 1;
            } else {
                itemsInRow = itemsCount;
            }
            setGridClasses(`tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-${itemsInRow} lg:tw-grid-cols-${itemsInRow} tw-gap-4 tw-gap-y-6`);
        }

    }, [FeaturedItems]);

    const renderItems = () => {
        return FeaturedItems.map((item, index) => (
            <a href={item.PropertyUrl} key={index} className="card">
                <div className="image-container">
                    {(item.Image && item.Image.Url) ? (
                        <img src={item.Image.Url} alt={item.Image.Name} className="image" />
                    ) : (
                        <span className="image-placeholder">Image Not Available</span>
                    )}
                    <h4 className="image-title">{item.Title}</h4>
                </div>
            </a>
        ));
    };

    return (
        <WidgetWrapper data={data}>
            <h2>{Title}</h2>
            <h3>{SubTitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: Content }} />

            {FeaturedItems.length > 4 ? (
                <Swiper
                    spaceBetween={10}
                    slidesPerView={slidesPerView}
                    navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }} // Add arrows navigation
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {FeaturedItems && FeaturedItems.map((item, index) => (
                        <SwiperSlide key={index}>
                            <a href={item.PropertyUrl}>
                                <div className="image-container">
                                    {(item.Image && item.Image.Url) ? (
                                        <img src={item.Image.Url} alt={item.Image.Name} className="image" />
                                    ) : (
                                        <span className="image-placeholder">Image Not Available</span>
                                    )}
                                    <h4 className="image-title">{item.Title}</h4>
                                </div>
                            </a>
                        </SwiperSlide>
                    ))}
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </Swiper>
            ) : (
                <div className={gridClasses}>
                    {renderItems()}
                </div>
            )}
        </WidgetWrapper>
    );
}

export default CarouselWidget;