import { useEffect, useState, useContext } from 'react';
import { SEOContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';
import WidgetsList from 'components/WidgetsList/WidgetsList';
import SkeletonHome from './SkeletonHome/SkeletonHome';

const HomePage = ({ pageId, keyID }) => {
    const { updateSeo } = useContext(SEOContext);
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [widgets, setWidgets] = useState([]);  
    const [response, setResponse] = useState(null);
    const [settings, setSettings] = useState(null); 
    const getContent = (sortDirection, Sort, Show, Page, clear) => {
     
        if (pageId) {
            fetch(`${process.env.REACT_APP_BASE_URL}apis/homepage?pageid=${pageId}&location=${pageId}&clearcache=${clear}&SortDirection=${sortDirection}&Sort=${Sort}&Currency=${currency}&Show=${Show}&Page=${Page}`)
                .then(response => response.json())
                .then(data => {
                    setSettings(data.Settings.SearchFormSettings);
                    setWidgets(data.Home.Widgets);
                    setResponse(true);
                    updateSeo(data.Settings.Seo);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    useEffect(() => {
        getContent();
    }, [pageId, currency])

    if (!response){
        return <SkeletonHome />
    }

 
    return (
        <>
            <WidgetsList Widgets={widgets} pageId={pageId} keyID={keyID} settings={settings} currency={currency} />
        </>
    );
};



export default HomePage;
