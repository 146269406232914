import React from 'react';

const BreadCrumbs = ({ data }) => {
    return (
        <div className="property-section-breadcrumbs breadcrumbs tw-bg-slate-100 tw-p-2 tw-inline-block">
            {data.map((item, index) => (
                <span key={index}>
                    {index < data.length - 1 ? (
                        <>
                            <a href={item.Url}>{item.Title}</a>
                            {" > "}
                        </>
                    ) : (
                        <span>{item.Title}</span>
                    )}
                </span>
            ))}
        </div>
    );
};

export default BreadCrumbs;