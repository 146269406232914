import React from 'react';
import Skeleton from 'react-loading-skeleton';

const Rates = ({ rates, isLoading }) => {
    if (isLoading) {
        return (
            <div className="reviews-container">
                <h4 className="rates-title">Rates</h4>
                <Skeleton className="rates-skeleton" count={10} height={15} />
            </div>
        );
    }

    // Define the columns you want to display in the table
    const columns = [
        'StartDate',
        'EndDate',
        'Nightly',
        'MinStay',
        'Occupancy',
        'Weekly', 
        'Monthly', 
        'Weekendly', 
        'ArrivalDay',
        'Departure'
    ];

    // Map each column to its display name
    const columnHeaders = {
        StartDate: 'Start Date',
        EndDate: 'End Date',
        Nightly: 'Nightly Rate',
        MinStay: 'Minimum Stay',
        Occupancy: 'Occupancy',
        Weekly: 'Weekly Rate',
        Monthly: 'Monthly Rate',
        Weekendly: 'Weekend Rate',
        ArrivalDay: 'Arrival Day',
        Departure: 'Departure Day'
    };

    return (
        <div className="rates-container">
            <h4 className="rates-title">Rates</h4>
            <div className="table-responsive rates-table-wrapper">
                <table className="rate-table tw-w-full tw-table-auto tw-border tw-border-gray-300 tw-mt-2">
                    <thead className="rate-table-head">
                        <tr className="rate-table-row-header tw-bg-gray-100">
                            {/* Loop through columns to render table headers */}
                            {columns.map(column => (
                                <th
                                    key={column}
                                    className={`rate-table-header rate-table-header-${column.toLowerCase()} tw-border tw-border-gray-300 tw-px-4 tw-py-2`}
                                >
                                    {columnHeaders[column]}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="rate-table-body">
                        {/* Loop through rates to render table rows */}
                        {rates.map((rate, index) => (
                            <tr
                                key={index}
                                className={`rate-table-row rate-table-row-${index} ${index % 2 === 1 ? 'tw-bg-gray-50' : ''}`}
                            >
                                {columns.map(column => (
                                    <td
                                        key={column}
                                        className={`rate-table-cell rate-table-cell-${column.toLowerCase()} tw-border tw-border-gray-300 tw-px-4 tw-py-2`}
                                    >
                                        {/* Check if rate[column] exists and is not an empty string */}
                                        {rate[column] && rate[column] !== '' ? rate[column] : 'N/A'}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {/* Display a message if no rates are available */}
                {rates && rates.length === 0 && (
                    <div className="rates-empty-message">No rates available</div>
                )}
            </div>
        </div>
    );
};

export default Rates;
