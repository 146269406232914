import React from 'react';
import { FaBed, FaCouch } from 'react-icons/fa'; // Import sample icons
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Amenities = ({ propertyAttributes, displayMode = 'list', isLoading }) => {

    if (isLoading) {
        return (
            <div className="reviews-container">
                <h4>Amenities</h4>
                <Skeleton count={10} height={15} />
            </div>
        );
    }


    const renderContent = () => {
        if (displayMode === 'table') {
            return propertyAttributes.Categories.map(category => {
                const optionMap = category.Options.reduce((map, option) => {
                    map[option.Id] = option.Name;
                    return map;
                }, {});

                if (!category.SelectedOptions || category.SelectedOptions.length === 0) {
                    return null;
                }

                return (
                    <tr key={category.Id} className="tw-border-t tw-border-gray-200">
                        <td className="tw-px-4 tw-py-2">{category.Name}</td>
                        <td className="tw-px-4 tw-py-2">{category.SelectedOptions.map(id => optionMap[id]).join(", ")}</td>
                    </tr>
                );
            });
        } else {
            let prevColSpan = 1;
            return propertyAttributes.Categories.map(category => {
                const optionMap = category.Options.reduce((map, option) => {
                    map[option.Id] = option.Name;
                    return map;
                }, {});

                if (!category.SelectedOptions || category.SelectedOptions.length === 0) {
                    return null;
                }
                // Determine the column span for each category based on its number of selected options
                const optionsCount = category.SelectedOptions.length;
                let colSpan = 1; // default to 1

                if (optionsCount > 30) {
                    colSpan = 4;
                } else if (optionsCount > 20) {
                    colSpan = 3;
                } else if (optionsCount > 10) {
                    colSpan = 2;
                }


                // If the previous category also spanned 2 columns, set the current one to span 3
                if (prevColSpan === 2 && colSpan === 2) {
                    colSpan = 3;
                }

                // Update prevColSpan for next iteration
                prevColSpan = colSpan;

                const divClass = `tw-mb-5 md:tw-mb-0 tw-p-4 tw-border tw-border-gray-200 tw-rounded tw-shadow-sm tw-bg-white tw-col-span-${colSpan}`;

                let ulClass = "tw-p-0 tw-m-0";
                if (category.SelectedOptions.length > 20) {
                    ulClass = "tw-p-0 tw-m-0 tw-grid tw-grid-cols-3 tw-gap-4";
                } else if (category.SelectedOptions.length > 6) {
                    ulClass = "tw-p-0 tw-m-0 tw-grid tw-grid-cols-2 tw-gap-x-4";
                }
                return (
                    <div key={category.Id} className={divClass} id={category.Id}>
                        <div className={`tw-flex tw-items-center tw-justify-between ${category.Name}-title`}>
                            <h4 className="tw-text-lg tw-font-semibold">{category.Name}</h4>
                        </div>
                        <ul className={ulClass}>
                            {category.SelectedOptions.map(id => (
                                <li key={id}>
                                    <span className="tw-mr-3"><FontAwesomeIcon icon={faCheck} /></span>
                                    {optionMap[id]}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            });
        }
    };

    return (
        <div className="amenities-container">
            <h4>Amenities</h4>
            <div className={displayMode === 'table' ? "tw-w-full tw-table tw-auto tw-border-collapse" : " md:tw-grid md:tw-grid-cols-3 lg:tw-grid-cols-3 tw-gap-4"}>
                {propertyAttributes.Categories ? renderContent() : null}
            </div>
        </div>
    );
};
export default Amenities;