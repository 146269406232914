import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import './Filters.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PriceRange from '../PriceRange/PriceRange';
import useGetUrlParams from 'utils/useGetUrlParams';

const FiltersMobile = ({
    changeNightRange,
    changeWeekRange,
    changeAttrsOnValueChange,
    changeAttrsOnLoad,
    startSearchFromModal,
    enablePriceRangeSlider,
    enabledFilters,
    attributesList,
    priceRangeSliderSettings,
    changePriceMode,
    clearField,
    propertyAttributesIds,
    changedPriceMode,
    changedWeekRange,
    changedNightRange
}) => {
    const refsList = useRef([]);
    const [show, setShow] = useState(false);
    const [isCleanedPrice, setIsCleanedPrice] = useState(false);
    const [checkedAttrs, setCheckedAttrs] = useState(0);
    const [priceRangeState, setPriceRangeState] = useState(false);
    const [startedSearch, setStartedSearch] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // Track modal open state
    const [selectedAttrs, setSelectedAttrs] = useState([]); // Track selected attributes

    const [nightlyParamsRange] = useGetUrlParams('NightlyPriceRange');
    const [weeklyParamsRange] = useGetUrlParams('WeeklyPriceRange');
    const [priceFilterType] = useGetUrlParams('PriceFilterType');
    const [attributes] = useGetUrlParams('attrs');
    const [attrs] = useGetUrlParams('Attributes');
    const [paramsAttributesOpenModal, setParamsAttributesOpenModal] = useState([]);

    const handleClose = () => {
        setShow(false);
    };

    const handleClocedModal = () => {
        setModalOpen(false);
    };

    const handleShow = () => {
        setShow(true);
        setStartedSearch(true);
    };

    const handleOpenModal = () => {
        setModalOpen(true);
        setStartedSearch(true);
    };

    useEffect(() => {
        if (clearField > 0) {
            handleReset();
        }
    }, [clearField]);

    useEffect(() => {
        if (nightlyParamsRange !== null) {
            changeNightRange(nightlyParamsRange || '');
        }
        if (weeklyParamsRange !== null) {
            changeWeekRange(weeklyParamsRange || '');
        }
    }, [priceRangeState]);

    useEffect(() => {
        if (priceFilterType !== null) {
            changePriceMode(priceFilterType || '');
        }
    }, [priceFilterType]);

    useEffect(() => {
        let allAttributes = attrs || attributes || propertyAttributesIds;
        if (allAttributes && paramsAttributesOpenModal.length === 0) {
            setParamsAttributesOpenModal(allAttributes.toString());
        }

        if (modalOpen) {
            // Ensure that when modal opens, we map pre-selected attributes
            let attrArr =
                typeof paramsAttributesOpenModal === 'string'
                    ? paramsAttributesOpenModal.split(',')
                    : paramsAttributesOpenModal;

            refsList.current.forEach((ref) => {
                if (ref && ref.tagName === 'INPUT' && attrArr.includes(ref.id)) {
                    ref.checked = true; // Check the checkbox if it's in the list
                }
                if (ref && ref.tagName === 'SELECT') {
                    for (let i = 0; i < ref.options.length; i++) {
                        if (attrArr.includes(ref.options[i].value)) {
                            ref.selectedIndex = i; // Pre-select the option that matches
                            break;
                        }
                    }
                }
            });
        }

        setPriceRangeState(true); // Initialize PriceRange Slider
    }, [modalOpen, paramsAttributesOpenModal, attributes, attrs, propertyAttributesIds]);

    useEffect(() => {
        let allAttributes = attributes || attrs || propertyAttributesIds;
        if (allAttributes) {
            let quantity = Array.isArray(allAttributes)
                ? allAttributes.filter((number) => number !== 'All')
                : allAttributes.split(',');

            setCheckedAttrs(quantity.length);
            changeAttrsOnLoad(allAttributes);
        }
    }, [attributes, attrs, propertyAttributesIds]);

    if (!enablePriceRangeSlider && !enabledFilters) {
        return null;
    }

    const handleChange = () => {
        let tempArr = [];

        refsList.current.forEach((ref) => {
            if (ref?.tagName === 'INPUT' && ref.checked) {
                tempArr.push(ref.id);
            }
            if (ref?.tagName === 'SELECT' && ref.value && ref.selectedIndex !== 0) {
                tempArr.push(ref.value);
            }
        });

        setSelectedAttrs(tempArr);
        changeAttrsOnValueChange(tempArr.toString());
        setCheckedAttrs(tempArr.length);
    };

    const startSearch = () => {
        startSearchFromModal();
        handleClose();
        window.scrollTo({ top: 250, behavior: 'smooth' });
    };

    const handleReset = () => {
        setIsCleanedPrice(!isCleanedPrice);
        refsList.current.forEach((ref) => {
            if (ref?.checked) ref.checked = false;
            if (ref?.tagName === 'SELECT') ref.selectedIndex = 0;
        });

        changeAttrsOnValueChange('');
        setParamsAttributesOpenModal([]);
        setCheckedAttrs(0);
        changeNightRange('');
        changeWeekRange('');
    };

    const attrsList = enabledFilters
        ? attributesList.map((element, index) => {
            let template;
            if (element.IsCheckBox === false) {
                const optionList = element.Options.map((option, i) => (
                    <option value={option.Id} key={i}>
                        {option.Name}
                    </option>
                ));

                let anyOption = '';
                if (!element.HideOptionAny) {
                    anyOption = <option value="">Any</option>; // Ensure 'Any' is added properly
                }

                template = (
                    <Form.Select
                        className="tw-max-w-[300px]"
                        ref={(el) => (refsList.current[index] = el)}
                        name={element.Alias}
                        onChange={handleChange}
                        defaultValue="" // Ensure select starts with the correct default value
                    >
                        {anyOption}
                        {optionList}
                    </Form.Select>
                );
            } else {
                const checkBoxList = element.Options.map((option, i) => (
                    <label className="tw-mr-5" key={i}>
                        <input
                            className="form-check-input"
                            ref={(el) => (refsList.current[option.Id] = el)}
                            id={option.Id}
                            type="checkbox"
                            onChange={handleChange}
                        />
                        <span className="tw-pl-2">{option.Name}</span>
                    </label>
                ));
                template = <div className="tw-grid lg:tw-grid-cols-3 xl:tw-grid-cols-4">{checkBoxList}</div>;
            }
            return (
                <div key={index} className="tw-py-2">
                    <h6>{element.Name}</h6>
                    {template}
                </div>
            );
        })
        : [];

    return (
        <>
            <div className="Filters">
                <span className="tw-cursor-pointer tw-items-center" onClick={handleShow}>
                    <h5 className="tw-border tw-p-5 tw-m-0 tw-w-full">
                        Filters
                        {checkedAttrs > 0 && (
                            <span className="tw-ml-2 badge rounded-pill bg-primary">
                                {checkedAttrs}
                                <svg
                                    className="svgFilters"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                >
                                    <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                </svg>
                            </span>
                        )}
                    </h5>
                </span>

                <Modal
                    backdrop="static"
                    size="lg"
                    scrollable={true}
                    show={show}
                    onHide={handleClose}
                    onEntered={handleOpenModal}
                    onExited={handleClocedModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Filters</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        {enablePriceRangeSlider && 
                            <PriceRange
                                changePriceMode={changePriceMode}
                                priceRangeSliderSettings={priceRangeSliderSettings}
                                changeNightRange={changeNightRange}
                                changeWeekRange={changeWeekRange}
                                priceRangeState={priceRangeState}
                                resetPrice={isCleanedPrice}
                                OpenedModal={startedSearch}
                                changedPriceMode={changedPriceMode}
                                changedWeekRange={changedWeekRange}
                                changedNightRange={changedNightRange}
                            />
                        }

                        {enablePriceRangeSlider}
                       
                           
                       
                        {enabledFilters && attrsList}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleReset} className="text-white">
                            Reset
                        </Button>
                        <Button variant="primary" onClick={startSearch} className="text-white">
                            Apply
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
};

export default FiltersMobile;
