
import Skeleton  from "react-loading-skeleton";
import SocialNetworks from 'components/SocialNetworks/SocialNetworks';
import './Breadcrumb.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faMobileRetro } from "@fortawesome/free-solid-svg-icons"
import React, { useEffect, useState } from 'react';


const Breadcrumb = (breadcrumbSettings ) => {



  let [isLoading, setIsLoading] = useState(true);
  let [enableBreadcrumb, setEnableBreadcrumb] = useState("");
  let [breadcrumbSeparator, setBreadcrumbSeparator] = useState('');
  
  let [enableLogin, setEnableLogin] = useState('');
  let [enableMobilephone, setEnableMobilephone] = useState('');
  let [enableSocialMediaBreadcrumb, setEnableSocialMediaBreadcrumb] = useState('');
  let [enableTelephone, setEnableTelephone] = useState('');

  let [сontactEmail, setContactEmail] = useState('');
  let [сontactMobilePhone, setContactMobilePhone] = useState('');
  let [сontactTelephone, setContactTelephone] = useState('');
  let [menuList, setmMenuList] = useState('');
  let [breadcrumbColorClass, setBreadcrumbColorClass] = useState('');

  useEffect(() => {
    let breadcrumbs = document.getElementsByTagName('body')[0].getAttribute('breadcrumbs');
    if (breadcrumbs === "1") {
      setEnableBreadcrumb(true);
      return;
    }
    setEnableBreadcrumb(false);
  }, []);
 
  useEffect(() => {
    
    if (breadcrumbSettings.breadcrumbSettings){
      setBreadcrumbSeparator(breadcrumbSettings.breadcrumbSettings.BreadcrumbSeparator);
      setEnableLogin(breadcrumbSettings.breadcrumbSettings.EnableLogin)
      setEnableMobilephone(breadcrumbSettings.breadcrumbSettings.EnableMobilephone);
      setEnableSocialMediaBreadcrumb(breadcrumbSettings.breadcrumbSettings.EnableSocialMediaBreadcrumb);
      setEnableTelephone(breadcrumbSettings.breadcrumbSettings.EnableTelephone);

      setContactEmail(breadcrumbSettings.siteSettings.ContactEmail);
      setContactMobilePhone(breadcrumbSettings.siteSettings.ContactMobilePhone);
      setContactTelephone(breadcrumbSettings.siteSettings.ContactTelephone);


      if (breadcrumbSettings.bodyClasses.includes("breadcrumb-dark")) {
        setBreadcrumbColorClass('tw-bg-primary_dark tw-text-white');
      }


      if (breadcrumbSettings.topRightLinks !== '') {
        let menuList = breadcrumbSettings.topRightLinks.map((menuItem) =>
          <li key={menuItem.Id} className="tw-hidden xl:tw-inline">
            <a href={menuItem.Url} className="tw-no-underline">{menuItem.Name}</a>
          </li>
        );
        setmMenuList(menuList);
      }

    }

 
  }, [breadcrumbSettings]);

  useEffect(() => {
    if (sessionStorage.getItem("sitesettings") === null) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1400);
    } else {
      setIsLoading(false);
    }

  }, []);

  if (enableBreadcrumb !== ""){
    if(!enableBreadcrumb){
      return;
    }
  }
 
  return(
    
      <>
      
 
      {!isLoading ?
        <div className={breadcrumbColorClass + " wrapper-breadcrumb  tw-border-b  tw-border-grey-500 tw-py-2  "} style={{ height: "41px" }}>
          
          <div className="container-fluid ">
          
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-[1fr,2fr] tw-place-content-between tw-auto-cols-auto">
              {/*breadcrumbSeparator*/}

              {enableSocialMediaBreadcrumb && <SocialNetworks size={'xl'} wrapperClass="tw-flex tw-gap-3 tw-justify-center md:tw-justify-start" className={"icons-social"} color={'primary'} socialNetworks={breadcrumbSettings.socialNetworks} />}

              <ul className="tw-p-0 tw-m-0 tw-flex tw-justify-end tw-gap-3">
                {enableLogin &&
                  <>
                    <li className="tw-hidden lg:tw-inline">
                      <a href="/members/login/" className="tw-no-underline">Login</a>
                    </li>
                    <li className="tw-hidden lg:tw-inline">
                      <a href="/members/owners-signup/" className="tw-no-underline">Register</a>
                    </li>
                    {menuList}
                  </>}



                {enableMobilephone && <li className="site-phone-mob tw-hidden md:tw-inline">
                  <a href={'tel:' + сontactMobilePhone.toString()} className="tw-no-underline">
                    <FontAwesomeIcon size="lg" icon={faMobileRetro} color="primary" className="tw-mr-2" />
                    <span className="site-phone-mob-text">{сontactMobilePhone}</span>
                  </a>
                </li>
                }
                {enableTelephone && <li className="site-phone tw-hidden md:tw-inline">
                  <a href={'tel:' + сontactTelephone.toString()} className="tw-no-underline">
                    <FontAwesomeIcon size="lg" icon={faPhone} color="primary" className="tw-mr-2" />
                    <span className="site-phone-text">{сontactTelephone}</span>
                  </a>
                </li>
                }
              </ul>
            </div>
          </div></div>: 

        <div className={"wrapper-breadcrumb  tw-border-b  tw-border-grey-500 tw-py-2 tw-bg-[#eee]"}>
          <div className="container-fluid ">
            <Skeleton height={24} />
          </div>
        </div>
        
      }
      
      </>

  )
};

Breadcrumb.propTypes = {};

Breadcrumb.defaultProps = {};

export default Breadcrumb;
