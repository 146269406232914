import React, { useEffect, useState } from 'react';
import useGetUrlParams from 'utils/useGetUrlParams';

const LocationMobile = ({
    onToggle,
    changeLocation,
    locationList,
    enableLocationFilter,
    clearField,
    onCloseSelect,
    settings,
    pageID,
    isOpen,
    locationsIds,
    changedLocation
}) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const [locationPlaceholder, setLocationPlaceholder] = useState("Anywhere");
    const [Location] = useGetUrlParams('Location');

  
    useEffect(() => {
        if (clearField > 0) {
            setSelectedOption([]);
            changeLocation('');
            setLocationPlaceholder("Anywhere");
        }
    }, [clearField]);



    useEffect(() => {
        if (pageID && locationList && locationsIds) {
            const selectedOptions = options.filter(option => locationsIds.includes(option.id))
                .map(option => ({ id: option.id, name: option.name }));

            setSelectedOption(selectedOptions);
        }
    }, [locationsIds]);

    useEffect(() => {
        if (pageID && locationList && changedLocation) {
            const selectedOptions = options.filter(option => changedLocation.includes(option.id))
                .map(option => ({ id: option.id, name: option.name }));

            setSelectedOption(selectedOptions);
        }
    }, []);

    useEffect(() => {

        if (pageID && locationList) {
            const optionMatchingPageID = options.find(option => option.id === pageID);

            if (optionMatchingPageID) {

                setSelectedOption([{ name: optionMatchingPageID.name, id: optionMatchingPageID.id }]);
                setTimeout(() => {
                    changeLocation(pageID.toString());
                }, 1);
            }
        }
    }, [Location, pageID, locationList]);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let location = urlParams.get('location') || urlParams.get('Location');
        let currentLocation = Location || location;

        const options = locationList.filter(({ level }) => level === 3).map(({ Id, Name }) => ({ name: Name, id: Id }));

        if (currentLocation) {
            changeLocation(currentLocation);
            const selectedIds = currentLocation.split(',').filter(Boolean).map(Number);
            const selectedOptions = options.filter(option => selectedIds.includes(option.id));
            setSelectedOption(selectedOptions);
            setLocationPlaceholder(selectedOptions.length ? `Locations (${selectedOptions.length})` : "Anywhere");
        }

        if (currentLocation === 'All' || currentLocation === '') {
            setLocationPlaceholder("Anywhere");
            changeLocation('');
            console.log('currentLocation', currentLocation);
        }
    }, [Location, locationList]);

    const options = locationList
        //?.filter(({ level }) => level === 3)
        .map(({ Id, Name }) => ({
            name: Name,
            id: Id,
            selected: selectedOption.some(option => option.id === Id)
        })) || [];

    // Dynamically adding a class to each `li` option after rendering
    useEffect(() => {
        // Make sure the location list exists and the DOM elements are rendered
        if (locationList) {
            const optionList = document.querySelectorAll('.optionContainer li');

            optionList.forEach((option, index) => {
                const locationItem = locationList[index]; // Get the corresponding item from locationList

                if (locationItem && locationItem.level) {
                    option.classList.add(`level-${locationItem.level}`); // Add a class based on the level
                }
            });
        }
    }, [locationList]);

    const changeLocaationParameter = (options) => {
        if (options.length === 0) setLocationPlaceholder("Anywhere");
        else setLocationPlaceholder(`Locations (${options.length})`);
        const ids = options.map(option => option.id).join(',');
        changeLocation(ids);
    };

    const handleCheckboxChange = (e, option) => {
        const updatedSelectedOptions = e.target.checked
            ? [...selectedOption, option]
            : selectedOption.filter(selected => selected.id !== option.id);

        setSelectedOption(updatedSelectedOptions);
        changeLocaationParameter(updatedSelectedOptions);
    };

    return (
        <div className={isOpen ? "tw-border tw-p-5" : ""}>
            <div className={!isOpen ? "tw-border tw-p-5" : ""} onClick={onToggle}>
                <h5 className='tw-m-0'>
                    {locationPlaceholder === "Anywhere" ? <span>Where to?</span> : <span>{locationPlaceholder}</span>}
                </h5>
            </div>
            {isOpen && enableLocationFilter && options.length > 0 && (
                <div className="location-buttons tw-mt-5">
                    {options.map(option => {
                        // Find the corresponding location from locationList to get the level
                        const locationItem = locationList.find(loc => loc.Id === option.id);
                        const levelClass = locationItem ? `level-${locationItem.level}` : '';

                        return (
                            <label key={option.id} className={`location-button ${option.selected ? 'selected' : ''} ${levelClass}`}>
                                <input
                                    type="checkbox"
                                    checked={option.selected}
                                    onChange={e => handleCheckboxChange(e, option)}
                                    className="hidden-checkbox"
                                />
                                {option.name}
                            </label>
                        );
                    })}
                </div>
            )}
        </div>
    );
};


export default LocationMobile;
