import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookiePolicyMessage.scss';

const CookiePolicyMessage = ({ text }) => {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const accepted = Cookies.get('acceptedCookiesPolicy');
        if (!accepted) {
            setShowMessage(true);
        }
    }, []);

    const handleAllowClick = () => {
        Cookies.set('acceptedCookiesPolicy', 'true', { expires: 365 }); // Set a cookie that expires in 1 year
        setShowMessage(false);
    }

    if (!showMessage) {
        return null;
    }

    return (
        <div className="alert-cookie tw-bottom-0 tw-fixed tw-inset-x-0 tw-bottom-0 tw-rounded-b tw-text-blue-900 tw-px-4 tw-py-3 tw-z-[9999999]" role="alert">
            <p className="tw-font-bold" dangerouslySetInnerHTML={{ __html: text }}></p>
            <button
                className={`allow-button tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-float-right`}
                onClick={handleAllowClick}>
                Allow
            </button>
        </div>
    );
}

export default CookiePolicyMessage;