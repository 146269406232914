import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import Skeleton from "react-loading-skeleton";
import styles from './SocialNetworks.module.css';

import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faPinterest } from "@fortawesome/free-brands-svg-icons"
import { faGoogle } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faYoutube } from "@fortawesome/free-brands-svg-icons"
import { faFlickr } from "@fortawesome/free-brands-svg-icons"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faBlog } from "@fortawesome/free-solid-svg-icons"


 
const SocialNetworks = (props) =>{

  
  const [facebook, setFacebook] = useState(props.socialNetworks.Facebook);
  const [pinterest, setPinterest] = useState(props.socialNetworks.Pinterest);
  const [google, setGoogle] = useState(props.socialNetworks.GooglePlus);
  const [twitter, setTwitter] = useState(props.socialNetworks.Twitter);
  const [linkedIn, setLinkedIn] = useState(props.socialNetworks.LinkedIn);
  const [youtube, setYoutube] = useState(props.socialNetworks.Youtube);
  const [flickr, setFlickr] = useState(props.socialNetworks.Flickr);
  const [instagram, setInstagram] = useState(props.socialNetworks.Instagram);
  const [blog, setBlog] = useState();
  const [iconSize, setSize] = useState()
  const [inconsClass, setIconsClass] = useState()

  useEffect(() => {
    setFacebook(props.socialNetworks.Facebook);
    setPinterest(props.socialNetworks.Pinterest);
    setGoogle(props.socialNetworks.GooglePlus);
    setTwitter(props.socialNetworks.Twitter);
    setLinkedIn(props.socialNetworks.LinkedIn);
    setYoutube(props.socialNetworks.YouTube);
    setFlickr(props.socialNetworks.Flickr);
    setInstagram(props.socialNetworks.Instagram);
    setBlog(props.socialNetworks.Blog);
    setSize(props.size);
    setIconsClass(props.wrapperClass);
     
  }, [props]);

  //console.log(props.socialNetworks);
  //console.log(props.color);
 
   
  return (
    <div className={styles.SocialNetworks + ' ' + inconsClass}>
      {facebook ?
        <a  target="_blank"  rel="noreferrer" href={facebook} >
          <FontAwesomeIcon size={iconSize} icon={faFacebook} color={props.color} className={props.className} />
        </a> : <Skeleton height={23} width={23} />
      }

      {pinterest &&
        <a  target="_blank"  rel="noreferrer" href={pinterest} >
          <FontAwesomeIcon size={iconSize} icon={faPinterest} color={props.color} className={props.className} />
        </a>
      }

      {google &&
        <a  target="_blank"  rel="noreferrer" href={google} >
          <FontAwesomeIcon size={iconSize} icon={faGoogle} color={props.color} className={props.className} />
        </a>
      }

      {twitter &&
        <a target="_blank" rel="noreferrer" href={twitter} >
          <FontAwesomeIcon size={iconSize} icon={faTwitter} color={props.color} className={props.className} />
        </a>
      }

      {linkedIn &&
        <a  target="_blank"  rel="noreferrer" href={linkedIn} >
          <FontAwesomeIcon size={iconSize} icon={faLinkedin} color={props.color} className={props.className} />
        </a>
      }

      {youtube &&
        <a  target="_blank"  rel="noreferrer" href={youtube} >
          <FontAwesomeIcon size={iconSize} icon={faYoutube} color={props.color} className={props.className} />
        </a>
      }


      {flickr &&
        <a  target="_blank"  rel="noreferrer" href={flickr} >
          <FontAwesomeIcon size={iconSize} icon={faFlickr} color={props.color} className={props.className} />
        </a>
      }

      {instagram &&
        <a target="_blank" rel="noreferrer" href={instagram} >
          <FontAwesomeIcon size={iconSize} icon={faInstagram} color={props.color} className={props.className} />
        </a>
      }
      
      {blog &&
        <a  target="_blank"  rel="noreferrer" href={blog} >
          <FontAwesomeIcon size={iconSize} icon={faBlog} color={props.color} className={props.className} />
        </a>
      }

    
    </div>
  )
};

SocialNetworks.propTypes = {};

SocialNetworks.defaultProps = {};

export default SocialNetworks;
