import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaStar } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './TestimonialsWidget.scss';
import useWindowSize from "utils/useWindowSize";

// Install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const TestimonialsWidget = ({ data }) => {
    const width = useWindowSize().width;
    const [slidesPerView, setSlidesPerView] = useState(5);

    useEffect(() => {
        if (width < 768) {
            setSlidesPerView(1);
        }
        else if (width >= 768 && width < 1024) {
            setSlidesPerView(2);
        }
        else if (width >= 1024 && width < 1366) {
            setSlidesPerView(3);
        }
        else if (width >= 1366 && width < 1450) {
            setSlidesPerView(4);
        }
        else if (width >= 1450 && width < 1600) {
            setSlidesPerView(4);
        }
        else {
            setSlidesPerView(5);
        }
    }, [width]);

    const truncateHTML = (html, maxLength) => {
        let trimmedString = html.length > maxLength
            ? html.substring(0, maxLength - 3) + "..."
            : html;

        const doc = new DOMParser().parseFromString(trimmedString, 'text/html');
        return doc.body.innerHTML;
    };

    return (
        <WidgetWrapper data={data}>
            <div className="tw-p-5 tw-shadow-lg tw-rounded-md TestimonialsWidget">
                <h2 className="tw-text-2xl tw-mb-1">{data.Title}</h2>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={slidesPerView}
                    autoHeight={false}
                    //autoplay={{ delay: 7000 }}
                    pagination={{ clickable: true, dynamicBullets: true }}
                >
                    {data.Testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <div className="tw-flex tw-flex-col tw-h-full">
                                <h2 className="tw-text-lg tw-mb-0 tw-min-h-[58px]">
                                    {testimonial.ReviewTitle}
                                </h2>
                                <div className="tw-flex-grow tw-mb-3">
                                    <FontAwesomeIcon
                                        icon={faQuoteLeft}
                                        className="tw-mr-2"
                                        style={{ 
                                            fontSize: '1.5rem',
                                            display: 'inline-block',
                                            float: 'left',
                                         }}
                                    />
                                    <p className='tw-min-h-[200px]' dangerouslySetInnerHTML={{ __html: truncateHTML(testimonial.ReviewDetail, 250) }}></p>
                                </div>
                                <div className="tw-flex tw-flex-col tw-justify-between tw-h-full">
                                    <div>
                                        <p className="tw-text-sm tw-italic">
                                            - {testimonial.GuestName}
                                            <div className="tw-flex tw-mt-1">
                                                {Array.from({ length: 5 }, (_, i) => (
                                                    <FaStar
                                                        key={i}
                                                        className={
                                                            i < Number(testimonial.RatingScore[0])
                                                                ? 'tw-text-yellow-500'
                                                                : 'tw-text-gray-300'
                                                        }
                                                    />
                                                ))}
                                            </div>
                                        </p>
                                    </div>
                                    <div className="tw-mt-auto tw-w-full tw-border-t tw-pt-2">
                                        <a href={testimonial.PropertyUrl} className="tw-text-primary tw-no-underline">
                                            {testimonial.PropertyName}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </WidgetWrapper>
    );
};

export default TestimonialsWidget;
