import React, { useState } from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import './TextBlockWidget.scss';

const TextBlockWidget = ({ data }) => {
    const [readMore, setReadMore] = useState(!data.Content);
    const TitleTag = data.TitleH1Tag || "h2";
    const SubTitleTag = data.SubTitleTag || "h3";

    const handleReadMoreClick = () => {
        setReadMore(!readMore);
    }

    return (
        <WidgetWrapper data={data}>
            {data.Title && <TitleTag>{data.Title}</TitleTag>}
            {data.SubTitle && <SubTitleTag>{data.SubTitle}</SubTitleTag>}
            {data.Content && <div dangerouslySetInnerHTML={{ __html: data.Content }} />}
            {data.ReadMore &&
                <>
                    <div style={{ display: readMore ? 'block' : 'none' }}
                        dangerouslySetInnerHTML={{ __html: data.ReadMore }} />
                    {data.Content && <button onClick={handleReadMoreClick}
                    className="tw-transition n-button ">
                        {readMore ? data.Dictionaries.HideText : data.Dictionaries.ReadMore}
                    </button>}
                </>
            }
        </WidgetWrapper>
    );
};

export default TextBlockWidget;