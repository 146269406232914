import React from 'react';
import PropTypes from 'prop-types';
import styles from './PropertyPrice.module.css';
import moment from 'moment';

const PropertyPrice = ({ item, dictionary }) => {
  // Determine price token (handle undefined dictionary or token)
  let priceToken = dictionary?.[item.WhatPriceTokenToUse];
  if (!priceToken) {
    console.warn('Missing or invalid price token. Using default display.');
    priceToken = '{price}'; // Default fallback
  }

  // Extract price, dates
  const price = item.Price;
  const start = moment(item.SearchStart);
  const end = moment(item.SearchEnd);
  const nights = end.diff(start, 'days') || 7;

  // Define replacement tokens
  const tokens = ['nights', 'price', 'date'];
  const tokensVars = [nights, price, start.format('YYYY-MM-DD')]; // Use formatted date

  // Perform token replacement with safer string manipulation
  const formattedPriceToken = priceToken.replace(/\{(\w+)\}/g, (match, token) => {
    const index = tokens.indexOf(token);
    if (index !== -1) {
      return tokensVars[index];
    }
    // Handle unknown tokens (avoid unintended replacements)
    console.warn(`Unknown token: ${token}. Leaving as-is.`);
    return match;
  });

  return (
    <div className={styles.PropertyPrice}>
      <div className="tw-font-bold tw-text-[1.2rem]" dangerouslySetInnerHTML={{ __html: formattedPriceToken }} />
    </div>
  );
};

PropertyPrice.propTypes = {
  item: PropTypes.shape({
    WhatPriceTokenToUse: PropTypes.string,
    Price: PropTypes.number.isRequired,
    SearchStart: PropTypes.string.isRequired,
    SearchEnd: PropTypes.string.isRequired,
  }).isRequired,
  dictionary: PropTypes.object,
};

PropertyPrice.defaultProps = {};

export default PropertyPrice;
