import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchSorter.module.css';
import Form from 'react-bootstrap/Form';
import { SortContext } from 'contexts/SiteSettingsProvider';

const SearchSorter = ({ updateSorting }) => {
  const { sort, order } = useContext(SortContext);
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    console.log('Current sort:', sort); // Log sort value
    console.log('Current order:', order); // Log order value

    if (sort && order) {
      const combinedValue = `${sort.toLowerCase()}:${order.toLowerCase()}`;
      console.log('Setting combined value:', combinedValue); // Log combined value
      setSelectedValue(combinedValue); // Set state with the combined value
    }
  }, [sort, order]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value); // Update the selected value in state
    const [newSort, newOrder] = value.split(':');

    // Update sorting with normalized case
    updateSorting(newSort.toLowerCase(), newOrder.toLowerCase());
  };

  return (
    <div className={styles.SearchSorter}>
      <Form.Select
        id="sortSelect"
        value={selectedValue} // Bind the state value to the select element
        onChange={handleSelectChange}
      >
        <option value="">Please Select</option>
        <option value="propertymaxrate:asc">Price low to high</option>
        <option value="propertymaxrate:desc">Price high to low</option>
        <option value="sleeps:asc">Sleeps: ascending</option>
        <option value="sleeps:desc">Sleeps: descending</option>
        <option value="bedrooms:asc">Bedrooms: ascending</option>
        <option value="bedrooms:desc">Bedrooms: descending</option>
        <option value="nodename:asc">Property name</option>
        <option value="propertyreference:asc">Property reference</option>
        <option value="ourpick:asc">Our Pick</option>
      </Form.Select>
    </div>
  );
};

SearchSorter.propTypes = {
  updateSorting: PropTypes.func.isRequired,
};

export default SearchSorter;
